import { useEffect, useState } from 'react';
import { AuthContextProps } from 'react-oidc-context';
 
const RETRY_DELAY_IN_MS = 2_000;
const MAX_RETRIES = 2;
 
export function useAdfsExpiredRefreshTokenHandling(auth: AuthContextProps) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
 
    useEffect(() => {
        (async function () {
            if (
                auth.error &&
                auth.error.message.includes('MSIS9615') &&
                !isProcessing &&
                retryCount <= MAX_RETRIES
            ) {
                // the `auth.error` will still be present until re-authentication finished; until then we need to prevent the code from re-executing
                setIsProcessing(true);
                console.info(
                    `Handling expired refresh token…${retryCount > 0 ? ` (retry attempt ${retryCount})` : ''}`,
                );
 
                try {
                    await auth.removeUser();
                    await auth.signinSilent();
 
                    setRetryCount(0);
                } catch (e) {
                    setRetryCount((c) => c + 1);
 
                    console.error(e);
                    console.log(
                        `Error in ADFS Expired Refresh Token Handling occurred, retrying in ${RETRY_DELAY_IN_MS}ms…`,
                    );
 
                    await delay(RETRY_DELAY_IN_MS);
                } finally {
                    setIsProcessing(false);
                }
            }
        })();
    }, [auth, isProcessing, retryCount]);
}
 
async function delay(timeInMs: number) {
    return new Promise<void>((resolve) => {
        setTimeout(() => resolve(), timeInMs);
    });
}